import { Dialog } from '~/components'
import { Divider, Typography, Button, Box, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'

type PayInvoceWithCashbackModalProps = {
  isVisible: boolean
  toggle: Function
}
export const PayInvoceWithCashbackModal: React.FC<PayInvoceWithCashbackModalProps> = ({ isVisible, toggle }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog title='Saque de cashback' onClose={() => toggle()} isVisible={isVisible}>
      <Divider sx={{ margin: '0 0 1.6rem 0' }}/>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 .6rem 0', flexWrap: 'wrap' }}>
        <Typography sx={{ color: theme.palette.grey['600'] }}>Sua última fatura em aberto tem o valor de </Typography>
        <Typography>R$ 300,00</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 .6rem 0', flexWrap: 'wrap' }}>
        <Typography sx={{ color: theme.palette.grey['600'] }}>Seu saldo pós pagamento será de </Typography>
        <Typography>R$ 146,00</Typography>
      </Box>

      <Typography sx={{ color: theme.palette.grey['600'], padding: '1rem 0 0 0' }}>Confirmar ação?</Typography>

      <Divider sx={{ margin: '2rem 0' }} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Button onClick={() => toggle()} color='secondary'>Cancelar</Button>
        <Button onClick={() => alert('Pagou a fatura')} type='submit'>Concluir</Button>
      </Box>
    </Dialog>
  )
}
