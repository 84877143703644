import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { useToggle } from '~/hooks'
import { Container } from './styles'
import { useContract } from '~/contexts'
import { UnifyEnergyBillFlow } from '../UnifyEnergyBillFlow'
import { UnifyEnergyBillSlide, CashbackOrDiscountBanner, CashbackJoinBanner } from './components'
import { ERewardType } from '~/graphql/types'
import 'slick-carousel/slick/slick.css'

const SampleNextArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <IconChevronRight size={48} color='#561885' />
    </button>
  )
}

const SamplePrevArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <IconChevronLeft size={48} color='#561885' />
    </button>
  )
}

export const HomeBanners: React.FC = () => {
  const { selectedContract } = useContract()
  const { isTrue: isVisible, toggle: toggleUnifyEnergyBillFlowModal, setIsTrue: setIsVisible } = useToggle()

  const shouldShowUnifiedEnergyBillBanner = !selectedContract?.singleAccount?.active
  const shouldShowCashbackJoinBanner = selectedContract?.reward?.type && selectedContract?.reward?.type === ERewardType.discount && selectedContract?.eligibleForCashback

  const settings: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    verticalSwiping: false,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false
        }
      }
    ]
  }

  const slideBanners = [<CashbackOrDiscountBanner />]

  // if(shouldShowUnifiedEnergyBillBanner) {
  //   slideBanners.push(<UnifyEnergyBillSlide toggleModal={toggleUnifyEnergyBillFlowModal} />)
  // }
  
  // if(shouldShowCashbackJoinBanner) {
  //   slideBanners.push(<CashbackJoinBanner />)
  // }

  return (
    <>
      <Container>
        {slideBanners.length > 1 ? (
          <Slider {...settings}>
            {slideBanners.map(item => item)}
          </Slider>
        ) : (
          <CashbackOrDiscountBanner />
        )}
      </Container>

      <UnifyEnergyBillFlow setIsVisible={setIsVisible} isVisible={isVisible} toggleModal={toggleUnifyEnergyBillFlowModal} />
    </>
  )
}
