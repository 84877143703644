import { Box, Typography, useMediaQuery, Button } from '@mui/material'
import { IconArrowDownLeft, IconArrowUpRight } from '@tabler/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Banner, Card, ContentTitle, PageLoader, Status, TableApi, TableHeaderApi } from '~/components'
import { useApiPagination, useDebounce, useToggle } from '~/hooks'
import { formatDate, formatMoney, theme, transformMoney } from '~/utils'
import { CashbackButton, SplitModal, PayInvoceWithCashbackModal } from './components'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'
import { useContract } from '~/contexts'
import { toast } from 'react-toastify'
import { 
  CustomerWithdrawRequestInputDto, 
  ERewardType, 
  useCustomerWithdrawRequestMutation, 
  useGetMyDigitalAccountWalletExtractCustomerUserQuery, 
  useWalletInformationsQuery 
} from '~/graphql/types'
import { Navigate } from 'react-router-dom'

export const Wallet: React.FC = () => {
  const { data: extractData, loading: extractIsLoading } = useGetMyDigitalAccountWalletExtractCustomerUserQuery({
    variables: {
      filters: {
        dateBegin: '2020/01/01',
        dateEnd: '2040/01/01',
        pageSize: 9999
      }
    }
  })
  const { data, loading: walletIsLoading } = useWalletInformationsQuery()
  const [customerWithdrawRequest, { loading: withdrawIsLoading }] = useCustomerWithdrawRequestMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      toast.success('Seu saque foi solicitado, agora é só aguardar!')
      splitModalToggle()
    },
  })

  const { selectedContract } = useContract()
  const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })
  const [filterName] = useState('')
  const debouncedFilterName = useDebounce(filterName, 1000)
  const { isTrue: splitModalIsVisible, toggle: splitModalToggle } = useToggle(false)
  const { isTrue: payInvoceModalIsVisible, toggle: payInvoceModalToggle } = useToggle(false)
  
  useEffect(() => {
    paginationData.setFiltersTable({ name: debouncedFilterName })
    paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  }, [debouncedFilterName])

  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const balance = data?.getMyDigitalAccountBalanceCustomerUser?.balanceCents || 0
  const hasMinusThenFiftyReais = balance < 5000 //50 reais
  const isCashback = selectedContract?.reward?.type === ERewardType.cashback

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'description',
        minWidth: 400,
        Cell: ({ value, row }: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem', maxWidth: '300px', padding: '.8rem 0' }}>
            <Box sx={{ 
              width: '44px',
              height: '44px',
              borderRadius: '.5rem',
              background: theme.palette.grey['100'],
              border: `1px solid ${theme.palette.grey['300']}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {row.original.operationType === 'C' ?
                <IconArrowDownLeft size={24} color={theme.palette.success.main} />
                :
                <IconArrowUpRight size={24} color={theme.palette.error.main} />
              }
            </Box>
            <Box>
              <Typography sx={{ fontSize: '1rem', color: theme.palette.black.main, fontWeight: 500 }}>{value.length > 40 ? `${value.substr(0, 40)}...` : value}</Typography>
              {/* {row.original.operationType === 'C' &&
                <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey['400'] }}>{!isLowerSm && 'Expira em '}{formatDate(row.original.expiredAt)}</Typography>
              } */}
            </Box>
          </Box>
        ) },
      {
        Header: 'Data',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Valor',
        accessor: 'valueCents',
        Cell: ({ value }) => <>{formatMoney(transformMoney(value, 'toReal'))}</>
      },
      {
        Header: 'Tipo',
        accessor: 'operationType',
        Cell: ({ value }) => (
          <>
            {value === 'C' ? <Status background={theme.palette.success.light} color={theme.palette.success.main} text='Entrada' />
            : <Status background={theme.palette.error.light} color={theme.palette.error.main} text='Saída' />}
          </>
        )
      }, 
      {
        Header: '',
        accessor: 'expiredAt',
        Cell: () => <></>
      },
      // {
      //   Header: 'Acessar',
      //   accessor: '_id',
      //   Cell: ({ value }) => (
      //     <>
      //       {hasPermission ? (
      //         <Link to={`/app/users/update/${value}`}>
      //           <IconButton>
      //             <IconArrowRight />
      //           </IconButton>
      //         </Link>
      //       ) : (
      //         <IconButton disabled={true}>
      //           <IconArrowRight />
      //         </IconButton>
      //       )}
      //     </>
      //   )
      // }, 
    ]
  }, [])

  const dataWithMemo = useMemo(() => extractData?.getMyDigitalAccountWalletExtractCustomerUser?.data || [], [extractData])

  const defaultColumnHiddens = isLowerMd ? ['type'].concat(isLowerSm ? ['createdAt'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>
    },
  }, 
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  //disable checkboxColumn
  // (hooks) => renderTableCheckbox(hooks)
  )

  const withdrawRequest = useCallback((params: CustomerWithdrawRequestInputDto) => {
    customerWithdrawRequest({
      variables: {
        params
      }
    })
  }, [])

  if(selectedContract?.reward?.type === ERewardType.discount) return <Navigate to={`/app/contracts/${selectedContract._id}/`} />

  if(walletIsLoading || extractIsLoading) return <PageLoader />

  return (
    <>
      <Box padding={0} display='flex' flexDirection='column'>

        <Card>

          <ContentTitle
            description='Escolha a melhor forma de utilizar seu saldo'
            title='Usar cashback'
            style={{ padding: '0px 0px 1.6rem 0px' }}
            breadcrumbLinks={{ currentLink: 'Carteira', links: [{ href: '/app', label: 'Área do cliente' }] }}
          />

          {hasMinusThenFiftyReais && 
            <Banner text='Você deve ter ao menos R$ 50,00 de saldo na carteira para realizar transações.' />
          }

          <Box sx={{ display: 'flex', alignItems: isLowerMd ? 'flex-start' : 'center', width: '100%', gap: '1rem', padding: '2rem 0', flexFlow: isLowerMd ? 'column' : 'row' }}>
            <Box sx={{ 
              border: `1px solid ${theme.palette.yellow.main}`,
              padding: '1rem',
              borderRadius: '.6rem',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '.6rem',
              ...isCashback ? {
                flexFlow: 'column',
                alignItems: 'normal',
                width: '190px',
                height: '210px',
                flex: '0 0 190px',
              } : {
                flexFlow: 'row',
                alignItems: 'center',
                width: '210px',
                height: 'auto',
                flex: '0 0 210px',
              },
              background: theme.palette.yellow[100]
            }}>
              <img style={{ width: isCashback ? '70px' : '40px' }} src={DespertaLogoReduced} alt='logo Desperta' />
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.6rem' }}>
                <Typography sx={{ fontSize: isCashback ? '1.2rem' : '1rem', color: theme.palette.grey['400'] }}>Saldo disponível</Typography>
                <Typography sx={{ fontSize: isCashback ? '1.4rem' : '1.2rem', fontWeight: 500, color: theme.palette.success.main }}>{formatMoney(transformMoney(balance, 'toReal'))}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexFlow: 'column', width: '100%', gap: '1rem' }}>
              {isCashback && 
                <CashbackButton disabled onClick={() => payInvoceModalToggle()} typeButton='invoce' />
              }
              <CashbackButton disabled={hasMinusThenFiftyReais} onClick={() => splitModalToggle()} typeButton='split' />
            </Box>
          </Box>
        
        </Card>
        <Card sx={{ marginTop: '2rem', padding: '0 2rem' }}>

          <TableHeaderApi
            styles={{ hasBorderBottom: true, hasBorderTop: false }}
            startElement={(
              <>
                <Typography color={theme.palette.grey['800']} fontSize={isLowerSm ? '1.1rem' : '1.6rem'} variant='h6'>Extrato de cashback</Typography>
              </>
            )}
            // endElement={
            //   (
            //     <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}> 
            //       <Button size={isLowerSm ? 'small' : 'medium'} color='secondary'>Filtros</Button>
            //     </Box>
            //   )
            // }
          />
          <TableApi
            isLoading={false}
            paginationData={paginationData}
            tableMethods={tableMethods}
            columns={columns}
          />
        </Card>
      </Box>
      <SplitModal withdrawIsLoading={withdrawIsLoading} balance={balance} withdrawRequest={withdrawRequest} toggle={splitModalToggle} isVisible={splitModalIsVisible} />
      <PayInvoceWithCashbackModal isVisible={payInvoceModalIsVisible} toggle={payInvoceModalToggle} />
    </>
  )
}
