import { PropsWithChildren, useMemo } from 'react'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import { PropsWithWizard } from '~/components'
import * as illustrations from '~/assets/illustrations'

type Illustration = keyof typeof illustrations

type ButtonActions = {
  title: string,
  onClick: () => void
  icon?: JSX.Element
}

export type UnifyEnergyBillLayoutProps = {
  loading?: boolean
  illustration: Illustration
  shouldShowProgress?: boolean
  backButton: ButtonActions | null
  nextButton: ButtonActions | null
}

export const UnifyEnergyBillLayout: React.FC<PropsWithChildren<PropsWithWizard<UnifyEnergyBillLayoutProps>>> = (props) => {
  const { children, currentPage, illustration, backButton, nextButton, shouldShowProgress = true, loading = false } = props

  const IllustrationComponent = useMemo(() => illustrations[illustration], [illustration])

  const dots = useMemo(() => {
    return new Array(3).fill(undefined).map((_, index) => (
      <Box key={`wizard-energy-bill-${index}`} sx={{
        display: 'flex',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        background: index === currentPage ? '#F9DC45' : '#CDCDCD',
      }} />
    ))
  }, [currentPage])

  return (
    <Box sx={{
      gap: 4,
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Divider sx={{ marginTop: '16px' }} />

      <IllustrationComponent style={{ fontSize: 230, alignSelf: 'center' }} />

      {children}

      {shouldShowProgress && (
        <Box sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {dots}
        </Box>
      )}

      {(Boolean(backButton) || Boolean(nextButton)) && (
        <>
          <Divider />

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Button disabled={loading} color='secondary' onClick={backButton?.onClick} startIcon={backButton?.icon || null}>
              {backButton?.title}
            </Button>
            <Button disabled={loading} onClick={nextButton?.onClick} endIcon={loading ? <CircularProgress size={24} color='inherit' /> : nextButton?.icon || null}>
              {nextButton?.title}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
